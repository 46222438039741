import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { constants } from './utils'
import { PageWrapper } from './components'
import { Home, Projects } from './screens'

const { ROUTES } = constants

function App() {
  return (
    <PageWrapper>
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.PROJECTS} element={<Projects />} />
          <Route path={ROUTES.HOME} element={<Home />} />
        </Routes>
      </BrowserRouter>
    </PageWrapper>
  )
}

export default App

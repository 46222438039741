import styled from 'styled-components'

export default styled.div`
  max-width: 666px;
  margin: 0 auto;
  padding: 0 2em 3em;

  display: flex;
  flex-direction: column;
`

import React from 'react'
import styled from 'styled-components'

import SimpleListItem from './SimpleListItem'

const Ul = styled.ul`
  font-family: 'Open Sans', sans-serif;
  margin-top: 0.75em;
`

export default ({ items }) => (
  <Ul>
    {items.map(item => (
      <SimpleListItem>{item}</SimpleListItem>
    ))}
  </Ul>
)

import React from 'react'

import { Hero, AboutMe, Skills, Interests, Contact } from './elements'

export default () => {
  return (
    <>
      <Hero />
      <AboutMe />
      <Interests />
      <Skills />
      <Contact />
    </>
  )
}

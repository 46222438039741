import React from 'react'

import { CustomLink, Section, Text, Title } from '../../../components'
import { constants } from '../../../utils'

const { CONTACTS } = constants

export default () => (
  <Section className="contact">
    <Title>Let's keep in touch</Title>
    <Text>
      {CONTACTS.map((item, index) => (
        <>
          <CustomLink href={item.URL} target="_blank">
            {item.DESCRIPTION}
          </CustomLink>

          {index < CONTACTS.length - 1 && <span>, </span>}
        </>
      ))}
      {` or by email `}
      <CustomLink href="mailto:gabrielrigon@outlook.com">
        gabrielrigon@outlook.com
      </CustomLink>
    </Text>
  </Section>
)

import React from 'react'

import { constants } from '../../../utils'
import { CustomLink, Section, Text, Title } from '../../../components'
import { SimpleList } from '../../../components/List'

const { ROUTES, SKILLS } = constants

export default () => (
  <Section className="skills">
    <Title>Skills</Title>
    <Text>
      Each work has its own needs. These are the ones I use the most, but I don't
      limit myself to them.
    </Text>
    <SimpleList items={SKILLS} />
    <CustomLink href={ROUTES.PROJECTS}>
      Recent projects
    </CustomLink>
  </Section>
)

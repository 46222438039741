import React from 'react'
import styled from 'styled-components'

import { Section } from '../../../components'

const Name = styled.h1`
  font-family: 'Abril Fatface', cursive;
  font-size: 3rem;
  line-height: 2.5rem;
  margin-top: 1em;
  margin-bottom: 0.5em;

  @media (min-width: 768px) {
    font-size: 4.5rem;
  }
`

export default () => (
  <Section className="hero">
    <Name>Gabriel Rigon</Name>
  </Section>
)

import React from 'react'

import { CustomLink, Section, Text, Title } from '../../../components'

export default () => (
  <Section className="about-me">
    <Title>About me</Title>
    <Text>I love great food, the beach, trekking, travel, sun, and (obviously) TECH!</Text>
    <Text>
      Technology is everything that helps us to evolve as a society;
      therefore, I believe that every solution should adapt to the need,
      never the opposite.
    </Text>
    <Text>
      {`I'm a developer since 2014 and currently I work at `}
      <CustomLink href="https://www.linkedin.com/company/appolustecnologia" target="_blank">
        Appolus Tecnologia
      </CustomLink>
      {` as Staff Software Engineer.`}
    </Text>
  </Section>
)

import React from 'react'

import { Section, Text, Title } from '../../../components'

export default () => (
  <Section className="interests">
    <Title>Interests</Title>
    <Text>
      Leadership in Tech, Software Architecture, Software Development and Infrastructure
    </Text>
  </Section>
)

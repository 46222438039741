import React from 'react'
import styled from 'styled-components'

import { constants } from '../../utils'
import { CustomLink, PageTitle } from '../../components'
import { ProjectsList } from './elements'

const { ROUTES, PROJECTS } = constants

const BackToHome = styled(CustomLink)`
  margin-top: 3em;
`

export default () => (
  <>
    <BackToHome href={ROUTES.HOME}>{'< Back'}</BackToHome>
    <PageTitle>Recent projects</PageTitle>
    <ProjectsList items={PROJECTS} />
  </>
)

import React from 'react'
import styled from 'styled-components'

const Li = styled.li`
  margin-bottom: 1.3em;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Description = styled.p`
  font-family: 'Open Sans', sans-serif;
  margin-top: 0;
  margin-bottom: 0;
`

const Project = styled.p`
  font-family: 'Open Sans', sans-serif;
  margin-top: 0.3em;
  margin-bottom: 0;
  font-size: 0.9em;
`

const Technologies = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.9em;
  margin-top: 0.3em;
  margin-bottom: 0;
`

export default ({ item }) => {
  const { PROJECT, DESCRIPTION, TECHNOLOGIES } = item || {}

  return (
    <>
      <Li>
        <Wrapper>
          <Description>{DESCRIPTION}</Description>
          <Project>{PROJECT}</Project>
          <Technologies>{TECHNOLOGIES}</Technologies>
        </Wrapper>
      </Li>
    </>
  )
}

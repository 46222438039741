import React from 'react'
import styled from 'styled-components'

import ProjectsListItem from './ProjectsListItem'

const Ul = styled.ul``

export default ({ items }) => (
  <Ul>
    {items.map(item => (
      <ProjectsListItem item={item} />
    ))}
  </Ul>
)
